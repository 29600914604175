import './Footer.scss';

export function Footer()  {

  return (
    <footer>
      <div className="container">
      <h3>FOOTER</h3>
      </div>
    </footer>
  );
}