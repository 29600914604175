import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { lazyImport } from "../utils/lazyImport";
import { Container } from '../components/layout/Container';
import { Loader } from "../components/elements/Loader";

const { Home } = lazyImport(() => import('./Home'), 'Home');
const { Profile } = lazyImport(() => import('./Profile'), 'Profile');
const { Contact } = lazyImport(() => import('./Contact'), 'Contact');
const { Services } = lazyImport(() => import('./Services'), 'Services');


function App() {
  

  return (
    <>
    <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <Container>
        <Switch>
          <Route exact path={`/`}  render={(routerProps: any) => ( <Home /> )} />
          <Route exact path={`/profile`} render={(routerProps: any) => ( <Profile /> )} />
          <Route exact path={`/contact`} render={(routerProps: any) => ( <Contact /> )} />
          <Route exact path={`/services`} render={(routerProps: any) => ( <Services /> )} />
        </Switch>
        </Container>
      </Suspense>
    </BrowserRouter>
    </>
  );
}

export default App;
